
let AnkaPanAPI
let Base
let PanoGL
let AnkaScalable
let GeomDataHolder
let ScalablePlugin
let AnkaPromise
let AnkaFetch
let GeometryObserver
let CursorManager
let Renderer
let SLine
let SPoint
let SHLine
let SPolygon
let setDef
let EventLine
let MeshMousePicker
if (window.AnkaPanAPI) {
    AnkaPanAPI = window.AnkaPanAPI
    Base = AnkaPanAPI.Base
    CursorManager = AnkaPanAPI.CursorManager
    PanoGL = AnkaPanAPI.PanoGL
    AnkaPromise = AnkaPanAPI.AnkaPromise
    AnkaFetch = AnkaPanAPI.AnkaFetch
    Renderer = AnkaPanAPI.Renderer
    setDef = AnkaPanAPI.Utils.setDef
    MeshMousePicker = AnkaPanAPI.MeshMousePicker
} else {
    throw new Error('AnkaPanAPI could be found in page.')
}


if (window.AnkaScalable) {
    AnkaScalable = window.AnkaScalable
    SLine = AnkaScalable.SLine
    EventLine = AnkaScalable.EventLine
    SPoint = AnkaScalable.SPoint
    SHLine = AnkaScalable.SHLine
    SPolygon = AnkaScalable.SPolygon
    GeomDataHolder = AnkaScalable.GeomDataHolder
    GeometryObserver = AnkaScalable.GeometryObserver
    ScalablePlugin = AnkaScalable.ScalablePlugin
} else {
    throw new Error('AnkaScalable could be found in page.')
}

let Points
let Scene
let Mesh
let Color
let BoxBufferGeometry
let BufferAttribute
let BufferGeometry
let Object3D
let SphereBufferGeometry
let Box3
let Geometry
let PointsMaterial
let Sphere
let MeshBasicMaterial
let Vector3
if (window.THREE) {
    Mesh = THREE.Mesh
    BoxBufferGeometry = THREE.BoxBufferGeometry
    BufferGeometry = THREE.BufferGeometry
    BufferAttribute = THREE.BufferAttribute
    SphereBufferGeometry = THREE.SphereBufferGeometry
    MeshBasicMaterial = THREE.MeshBasicMaterial
    Box3 = THREE.Box3
    PointsMaterial = THREE.PointsMaterial
    Color = THREE.Color
    Scene = THREE.Scene
    Object3D = THREE.Object3D
    Geometry = THREE.Geometry
    Sphere = THREE.Sphere
    Points = THREE.Points
    Vector3 = THREE.Vector3
} else {
    throw new Error('THREE.js could be found in page.')
}

export {
    AnkaFetch,
    AnkaPromise,
    Base,
    Box3,
    BoxBufferGeometry,
    BufferAttribute,
    BufferGeometry,
    Color,
    CursorManager,
    Geometry,
    GeomDataHolder,
    GeometryObserver,
    EventLine,
    Mesh,
    MeshMousePicker,
    Object3D,
    MeshBasicMaterial,
    PanoGL,
    Points,
    PointsMaterial,
    Renderer,
    ScalablePlugin,
    Scene,
    setDef,
    Sphere,
    SHLine,
    SLine,
    SPoint,
    SPolygon,
    SphereBufferGeometry,
    Vector3,
}
